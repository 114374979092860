<template>
  <div>
    <page-header>
      {{ $t('ingredientSelect') }}
      <template #action>
        <v-item-group
          v-model="tab"
          mandatory
        >
          <div class="d-flex">
            <v-item v-slot="{active,toggle}">
              <v-btn
                :color="active?'primary lighten-4 black--text':''"
                :outlined="!active"
                class="mr-1"
                rounded
                @click="toggle"
              >
                {{ $t('product') }}
              </v-btn>
            </v-item>
            <v-item v-slot="{active,toggle}">
              <v-btn
                :color="active?'primary lighten-4 black--text':''"
                :outlined="!active"
                class="mr-1"
                rounded
                @click="toggle"
              >
                {{ $t('ingredient') }}
              </v-btn>
            </v-item>
          </div>
        </v-item-group>
      </template>
    </page-header>
    <div class="py-2">
      <v-text-field
        ref="search"
        v-model="search"
        :placeholder="$t('name')"
        autofocus
        clearable
        hide-details
        outlined
        prepend-inner-icon="mdi-magnify"
        @click="realSearch='';search=''"
      />
    </div>
    <v-tabs-items v-model="tab">
      <template v-if="!isLoading">
        <v-tab-item>
          <div
            class="d-flex flex-column mt-4"
            style="height: calc(100vh - 108px);"
          >
            <div
              class="flex-shrink-1"
              style="display: grid;grid-template-columns: 1fr;grid-gap: 4px;
        overflow-y: scroll"
            >
              <v-card
                v-for="item in dishFilteredList"
                :key="item.id"
                class="pa-2"
                elevation="0"
                outlined
              >
                <v-container>
                  <v-row class="align-center">
                    <v-col cols="8">
                      <div class="text-h5 font-weight-bold">
                        {{ $t('name') }}:{{ item.dishName }}
                      </div>
                      <div>{{ $t('ingredient') }}: {{ item.ingredientListName }}</div>
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="2"
                    >
                      <v-btn
                        class="font-weight-bold text-caption d-flex justify-center"
                        outlined
                        :icon="$vuetify.breakpoint.mdAndDown"
                        style="color: #3063E9"
                        @click="editNutritionList(item)"
                      >
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndDown"
                          color="black"
                        >
                          mdi-format-list-numbered-rtl
                        </v-icon>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">
                          <div
                            style="max-width: 100px"
                            class="text-truncate"
                          >
                            {{ $t('editNutrition') }}
                          </div>
                        </template>
                      </v-btn>
                      <v-btn
                        class="font-weight-bold text-caption mt-4 d-flex justify-center"
                        outlined
                        :icon="$vuetify.breakpoint.mdAndDown"
                        style="color: #3063E9"
                        @click="selectIngredient(item)"
                      >
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndDown"
                          color="black"
                        >
                          mdi-fruit-cherries
                        </v-icon>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">
                          <div
                            style="max-width: 100px"
                            class="text-truncate"
                          >
                            {{ $t('ingredientSelect') }}
                          </div>
                        </template>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="mt-2"
                      cols="2"
                    >
                      <v-btn
                        :icon="$vuetify.breakpoint.mdAndDown"
                        class="font-weight-bold text-caption d-flex justify-center"
                        outlined
                        style="color: #3063E9"
                        @click="editAttention(item)"
                      >
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndDown"
                          color="black"
                        >
                          mdi-alert
                        </v-icon>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">
                          <div
                            style="max-width: 100px"
                            class="text-truncate"
                          >
                            {{ $t('editPreparing') }}
                          </div>
                        </template>
                      </v-btn>
                      <v-btn
                        :icon="$vuetify.breakpoint.mdAndDown"
                        class="font-weight-bold text-caption mt-4 d-flex justify-center"
                        outlined
                        style="color: #3063E9"
                        @click="selectDish(item)"
                      >
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndDown"
                          color="black"
                        >
                          mdi-barcode-scan
                        </v-icon>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">
                          <div
                            style="max-width: 100px"
                            class="text-truncate"
                          >
                            {{ $t('selectSKU') }}
                          </div>
                        </template>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div
            class="d-flex flex-column mt-4"
            style="height: calc(100vh - 108px);"
          >
            <div
              class="flex-shrink-1"
              style="display: grid;grid-template-columns: 1fr;grid-gap: 4px;
        overflow-y: scroll"
            >
              <v-card
                v-for="item in attrFilteredList"
                :key="item.id"
                class="pa-2"
                elevation="0"
                outlined
              >
                <v-container>
                  <v-row class="align-center">
                    <v-col cols="8">
                      <div class="text-h5 font-weight-bold">
                        {{ $t('name') }}:{{ item.name }}
                      </div>
                      <div>{{ $t('ingredient') }}: {{ item.ingredientListName }}</div>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="font-weight-bold text-caption d-flex justify-center"
                        outlined
                        :icon="$vuetify.breakpoint.mdAndDown"
                        style="color: #3063E9"
                        @click="editNutritionList(item)"
                      >
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndDown"
                          color="black"
                        >
                          mdi-format-list-numbered-rtl
                        </v-icon>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">
                          <div
                            style="max-width: 100px"
                            class="text-truncate"
                          >
                            {{ $t('editNutrition') }}
                          </div>
                        </template>
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        :icon="$vuetify.breakpoint.mdAndDown"
                        class="font-weight-bold text-caption d-flex justify-center"
                        outlined
                        style="color: #3063E9"
                        @click="selectIngredient(item)"
                      >
                        <v-icon
                          v-if="$vuetify.breakpoint.mdAndDown"
                          color="black"
                        >
                          mdi-fruit-cherries
                        </v-icon>
                        <template v-if="!$vuetify.breakpoint.mdAndDown">
                          <div
                            style="max-width: 100px"
                            class="text-truncate"
                          >
                            {{ $t('ingredientSelect') }}
                          </div>
                        </template>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </div>
        </v-tab-item>
      </template>
      <template v-else>
        <div
          class="d-flex align-center justify-center"
          style="height: 400px"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </div>
      </template>
    </v-tabs-items>
    <v-dialog
      v-model="editItem"
      max-width="800px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <template v-if="typeItem === 1">
            <div class="text-h3 font-weight-bold">
              {{ attrName }}:
            </div>
          </template>
          <template v-else>
            <div class="text-h3 font-weight-bold">
              {{ nameOfDish }}:
            </div>
          </template>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="editItem = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div
          class="mt-4"
          style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
        >
          <v-card
            class="d-flex align-center justify-center"
            elevation="0"
            outlined
            @click="selectIngredientToItem()"
          >
            <v-responsive
              :aspect-ratio="1"
            >
              <div
                class="d-flex align-center justify-center flex-column pa-2"
                style="height: 100%;position: relative"
              >
                <v-icon size="36">
                  mdi-plus
                </v-icon>
                <div class="text-center font-weight-bold">
                  {{ $t('addIngredient') }}
                </div>
              </div>
            </v-responsive>
          </v-card>
          <template v-if="typeItem === 1">
            <v-card
              v-for="i in attrItem"
              :key="i.id"
              class="d-flex align-center justify-center"
              elevation="0"
              outlined
            >
              <v-responsive
                :aspect-ratio="1"
              >
                <div
                  class="d-flex align-center justify-center flex-column pa-2"
                  style="height: 100%;position: relative"
                >
                  <div class="text-center font-weight-bold">
                    {{ i.name }}
                  </div>
                </div>
              </v-responsive>
            </v-card>
          </template>
          <template v-else>
            <v-card
              v-for="i in dishItem"
              :key="i.id"
              class="d-flex align-center justify-center"
              elevation="0"
              outlined
            >
              <v-responsive
                :aspect-ratio="1"
              >
                <div
                  class="d-flex align-center justify-center flex-column pa-2"
                  style="height: 100%;position: relative"
                >
                  <div class="text-center font-weight-bold">
                    {{ i.name }}
                  </div>
                </div>
              </v-responsive>
            </v-card>
          </template>
        </div>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="editItemSort = true, editItem = false"
          >
            {{ $t('editShowSort') }}
          </v-btn>
          <template v-if="typeItem === 1">
            <v-btn
              class="ml-2 green  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="attrSureSelect()"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="ml-2 green  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="dishSureSelect()"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editItemSort"
      max-width="800px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <template v-if="typeItem === 1">
            <div class="text-h3 font-weight-bold">
              {{ $t('editAttrIngredientSort', { attrName: 'attrName' }) }}
            </div>
          </template>
          <template v-else>
            <div class="text-h3 font-weight-bold">
              {{ $t('editDishIngredientSort', { nameOfDish: 'nameOfDish' }) }}
            </div>
          </template>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="editItemSort = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <template v-if="typeItem === 1">
          <draggable
            v-model="realAttrList"
            handle=".handle"
            style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
          >
            <v-card
              v-for="i in realAttrList"
              :key="i.id"
              class="d-flex align-center justify-center handle mt-4"
              elevation="0"
              outlined
            >
              <v-responsive
                :aspect-ratio="1"
              >
                <div
                  class="d-flex align-center justify-center flex-column pa-2"
                  style="height: 100%;position: relative"
                >
                  <div class="text-center font-weight-bold">
                    {{ i.name }}
                  </div>
                </div>
              </v-responsive>
            </v-card>
          </draggable>
        </template>
        <template v-else>
          <draggable
            v-model="realDishList"
            handle=".handle"
            style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
          >
            <v-card
              v-for="i in realDishList"
              :key="i.id"
              class="d-flex align-center justify-center handle mt-4"
              elevation="0"
              outlined
            >
              <v-responsive
                :aspect-ratio="1"
              >
                <div
                  class="d-flex align-center justify-center flex-column pa-2"
                  style="height: 100%;position: relative"
                >
                  <div class="text-center font-weight-bold">
                    {{ i.name }}
                  </div>
                </div>
              </v-responsive>
            </v-card>
          </draggable>
        </template>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="error  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="editItemSort = false, editItem = true"
          >
            {{ $t('return') }}
          </v-btn>
          <template v-if="typeItem === 1">
            <v-btn
              class="ml-2 green  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="saveAttrSort()"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="ml-2 green  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="saveDishSort"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ingredientSelectDialog"
      max-width="800px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <template v-if="typeItem === 1">
            <div class="text-h3 font-weight-bold">
              {{ attrName }}:
            </div>
          </template>
          <template v-else>
            <div class="text-h3 font-weight-bold">
              {{ nameOfDish }}:
            </div>
          </template>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="ingredientSelectDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="text-body-1">
          {{ $t('selectIngredientCanMultipleChoice') }}
        </div>
        <v-item-group
          v-model="ingredientSelect"
          multiple
        >
          <div
            class="mt-4"
            style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
          >
            <v-item
              v-for="i in IngredientList"
              :key="i.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="d-flex align-center justify-center"
                elevation="0"
                outlined
                @click="toggle()"
              >
                <v-responsive
                  :aspect-ratio="1"
                >
                  <div
                    class="d-flex align-center justify-center flex-column pa-2"
                    style="height: 100%;position: relative"
                  >
                    <v-icon
                      v-if="active"
                      color="primary"
                      size="36"
                      style="position: absolute;bottom: 0px;"
                    >
                      mdi-check-circle
                    </v-icon>
                    <div class="text-center font-weight-bold">
                      {{ i.name }}
                    </div>
                  </div>
                </v-responsive>
              </v-card>
            </v-item>
          </div>
        </v-item-group>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="error  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="ingredientSelectDialog = false,editItem = true"
          >
            {{ $t('cancel') }}
          </v-btn>
          <template v-if="typeItem === 1">
            <v-btn
              class="primary  mt-4 lighten-2 ml-2"
              elevation="0"
              style="border-radius: 36px"
              @click="attrSureSelect()"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="primary  mt-4 lighten-2 ml-2"
              elevation="0"
              style="border-radius: 36px"
              @click="dishSureSelect()"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editNutritionDialog"
      max-width="600px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <div class="text-h3 font-weight-bold">
            {{ $t('nutritionList') }}
          </div>
          <div class="text-body-1 mt-2">
            {{ $t('keepOneDecimal') }}
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="editNutritionDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="mt-4">
            <div>{{ $t('energy') }}</div>
            <v-text-field
              v-model="energy"
              autofocus
              dense
              hide-details
              outlined
              suffix="KJ/Kcal"
            />
          </div>
          <div class="d-flex mt-4">
            <div>
              <div>
                {{ $t('fat') }}
              </div>
              <v-text-field
                v-model="fat"
                dense
                hide-details
                outlined
                suffix="g"
              />
            </div>
            <v-spacer/>
            <div>
              <div>{{ $t('ofSFA') }}</div>
              <v-text-field
                v-model="ofSFA"
                dense
                hide-details
                outlined
                suffix="g"
              />
            </div>
          </div>
          <div class="d-flex mt-4">
            <div>
              <div>
                {{ $t('protein') }}
              </div>
              <v-text-field
                v-model="protein"
                dense
                hide-details
                outlined
                suffix="g"
              />
            </div>
            <v-spacer/>
            <div>
              <div>{{ $t('carbonHydrate') }}</div>
              <v-text-field
                v-model="carbonHydrate"
                dense
                hide-details
                outlined
                suffix="g"
              />
            </div>
          </div>
          <div class="d-flex mt-4">
            <div>
              <div>
                {{ $t('ofSugar') }}
              </div>
              <v-text-field
                v-model="sugar"
                dense
                hide-details
                outlined
                suffix="g"
              />
            </div>
            <v-spacer/>
            <div>
              <div>{{ $t('ofSalt') }}</div>
              <v-text-field
                v-model="salt"
                dense
                hide-details
                outlined
                suffix="g"
              />
            </div>
          </div>
        </v-form>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="saveNutrition()"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="selectSKUDialog"
      max-width="800px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <div class="text-h3 font-weight-bold">
            {{ selectedDish?.dishName }}:
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="selectSKUDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-item-group
          v-model="SKUSelect"
          multiple
        >
          <div
            class="mt-4"
            style="display: grid;grid-template-columns: repeat(8,minmax(0,1fr));grid-gap: 8px;width: 100%"
          >
            <v-item
              v-for="i in SKUList"
              :key="i.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="d-flex align-center justify-center"
                elevation="0"
                outlined
                @click="toggle()"
              >
                <v-responsive
                  :aspect-ratio="1"
                >
                  <div
                    class="d-flex align-center justify-center flex-column pa-2"
                    style="height: 100%;position: relative"
                  >
                    <v-icon
                      v-if="active"
                      color="primary"
                      size="36"
                      style="position: absolute;bottom: 0px;"
                    >
                      mdi-check-circle
                    </v-icon>
                    <div class="text-center font-weight-bold">
                      {{ i.name }}
                    </div>
                  </div>
                </v-responsive>
              </v-card>
            </v-item>
          </div>
        </v-item-group>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="SKUSureSelect()"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="attentionTo"
      max-width="600px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <div class="text-h3 font-weight-bold">
            {{ $t('attentionTo') }}
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="attentionTo = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="mt-4">
            <div>{{ $t('howToEat') }}</div>
            <v-text-field
              v-model="preparingText"
              autofocus
              dense
              hide-details
              outlined
            />
            <div class="mt-4">
              {{ $t('storageDays') }}
            </div>
            <v-text-field
              v-model="storageDays"
              :suffix="$t('days')"
              dense
              hide-details
              outlined
            />
            <div class="mt-4">
              {{ $t('temperature') }}
            </div>
            <v-text-field
              v-model="storageNotes"
              dense
              hide-details
              outlined
              suffix="℃"
              type="number"
            />
          </div>
        </v-form>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="saveAttention()"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import Dish from '@/model/dish/Dish'
  import Attribute from '@/model/dish/Attribute'
  import {
    editAttention,
    editAttrNutrition,
    editDishNutrition,
    getAttention,
    getAttrNutrition,
    getDishNutrition,
    getDishSKUList,
    getIngredientAttrListGroup,
    getIngredientByAttr,
    getIngredientByDish,
    getIngredientDishListGroup,
    getIngredientList,
    getSKUList,
    setSKUDish,
    settingDishIngredient,
    settingToppingIngredient,
  } from '@/common/Utlis/api'
  import draggable from 'vuedraggable'
  import { showSuccessMessage } from '@/common/utils'
  import PageHeader from '@/composable/InK/typography/PageHeader'

  export default {
    name: 'IngredientSetting',
    components: {
      draggable,
      PageHeader,
    },
    data: function () {
      return {
        attentionItem: null,
        preparingText: null,
        storageDays: null,
        storageNotes: null,
        attentionTo: false,
        search: '',
        realSearch: '',

        selectedDish: null,
        SKUSelect: [],
        SKUList: null,
        selectSKUDialog: null,
        attrEditItem: null,
        dishEditItem: null,
        energy: null,
        salt: null,
        sugar: null,
        carbonHydrate: null,
        protein: null,
        fat: null,
        ofSFA: null,

        valid: true,
        editNutritionDialog: false,
        ingredientListName: null,
        isLoading: false,
        typeItem: 0, // 0:Dish 1:Attr
        realAttrList: [],
        realDishList: [],
        editItemSort: false,
        ingredientSelectDialog: false,
        tab: null,
        dishList: null,
        attrList: null,
        IngredientList: null,
        attrName: null,
        ingredientSelect: null,
        attr: null,
        attrItem: null,
        editItem: null,
        dishItem: null,
        editDishItem: null,
        nameOfDish: null,
        itemOfDish: null,
      }
    },
    computed: {
      dishFilteredList () {
        if (!this.realSearch) {
          return this.dishList
        } else {
          return this.dishList.filter(
            d => {
              return d.dishName.toLowerCase().includes(this.realSearch.toLowerCase())
            })
        }
      },
      attrFilteredList () {
        if (!this.realSearch) {
          return this.attrList
        } else {
          return this.attrList.filter(
            d => {
              return d.name.toLowerCase().includes(this.realSearch.toLowerCase())
            })
        }
      },
    },
    watch: {
      search (val) {
        if (val !== '') {
          this.realSearch = val
        } else {
          this.realSearch = ''
        }
      },
      selectSKUDialog (val) {
        if (!val) {
          this.SKUSelect = []
        }
      },
      editNutritionDialog (val) {
        if (!val) {
          this.attrEditItem = ''
          this.dishEditItem = ''
          this.energy = ''
          this.fat = ''
          this.ofSFA = ''
          this.carbonHydrate = ''
          this.sugar = ''
          this.salt = ''
          this.protein = ''
        }
      },
      editItemSort (val) {
        if (!val) {
          this.realDishList = []
        }
      },
      ingredientSelectDialog (val) {
        if (!val) {
          this.ingredientSelect = ''
          this.dishItem = []
          this.attrItem = []
        }
      },
      attrItem: {
        immediate: true,
        handler () {
          if (this.attrItem?.length) {
            this.realAttrList = this.attrItem
          }
        },
      },
      dishItem: {
        immediate: true,
        handler () {
          if (this.dishItem?.length) {
            this.realDishList = this.dishItem
          }
        },

      },
    },
    mounted () {
      this.reload()
    },
    methods: {
      async saveAttention () {
        await editAttention({
          preparingText: this.preparingText,
          storageNotes: this.storageNotes,
          storageDays: this.storageDays,
        }, this.attentionItem.id)
        showSuccessMessage(this.$t('save_success'))
        this.attentionTo = false
        await this.reload()
      },
      async editAttention (item) {
        this.attentionItem = item
        const res = (await getAttention(item.id))[0]
        this.preparingText = res.preparingText
        this.storageNotes = res.storageNotes
        this.storageDays = res.storageDays
        this.attentionTo = true
      },
      async selectDish (item) {
        this.selectSKUDialog = true
        this.selectedDish = item
        const SKUListIndex = []
        const res = await getDishSKUList(item.id)
        for (const item of res) {
          const result = this.SKUList.findIndex(it => it.id === item.id)
          SKUListIndex.push(result)
        }
        this.SKUSelect = SKUListIndex
      },
      async SKUSureSelect () {
        await setSKUDish({
          dishId: this.selectedDish.id,
          idSorts: this.SKUSelect.map(it => ({
            id: parseInt(this.SKUList[it].id),
            sort: 1,
          })),
        })
        showSuccessMessage(this.$t('bindingSuccess'))
        this.selectSKUDialog = false
        await this.reload()
      },
      async saveNutrition () {
        if (this.dishEditItem) {
          await editDishNutrition({
            energyKJ: this.energy,
            fat: this.fat,
            ofSFA: this.ofSFA,
            carbonHydrate: this.carbonHydrate,
            ofSugar: this.sugar,
            salt: this.salt,
            protein: this.protein,
          }, this.dishEditItem.id)
        } else {
          await editAttrNutrition({
            energyKJ: this.energy,
            fat: this.fat,
            ofSFA: this.ofSFA,
            carbonHydrate: this.carbonHydrate,
            ofSugar: this.sugar,
            salt: this.salt,
            protein: this.protein,
          }, this.attrEditItem.id)
        }
        showSuccessMessage()
        this.editNutritionDialog = false
        await this.reload()
      },
      async editNutritionList (item) {
        if (item.itemType === 0) {
          this.dishEditItem = item
          const dishRes = (await getDishNutrition(item.id))[0]
          if (dishRes.energyKJ === '0.0') {
            this.energy = ''
            this.fat = ''
            this.ofSFA = ''
            this.carbonHydrate = ''
            this.sugar = ''
            this.salt = ''
            this.protein = ''
          } else {
            this.energy = dishRes.energyKJ
            this.fat = dishRes.fat
            this.ofSFA = dishRes.ofSFA
            this.carbonHydrate = dishRes.carbonHydrate
            this.sugar = dishRes.ofSugar
            this.salt = dishRes.salt
            this.protein = dishRes.protein
          }
        } else {
          this.attrEditItem = item
          const attrRes = (await getAttrNutrition(item.id))[0]
          if (attrRes.energyKJ === '0.0') {
            this.energy = ''
            this.fat = ''
            this.ofSFA = ''
            this.carbonHydrate = ''
            this.sugar = ''
            this.salt = ''
            this.protein = ''
          } else {
            this.energy = attrRes.energyKJ
            this.fat = attrRes.fat
            this.ofSFA = attrRes.ofSFA
            this.carbonHydrate = attrRes.carbonHydrate
            this.sugar = attrRes.ofSugar
            this.salt = attrRes.salt
            this.protein = attrRes.protein
          }
        }
        this.editNutritionDialog = true
      },
      selectIngredientToItem () {
        this.ingredientSelectDialog = true
        this.editItem = false
        if (this.tab === 0) {
          const array = []
          for (const item of this.dishItem) {
            const arr = this.IngredientList.findIndex(it => {
              return it.id === item.id
            })
            array.push(arr)
            this.ingredientSelect = array
          }
        } else {
          const array = []
          for (const item of this.attrItem) {
            const arr = this.IngredientList.findIndex(it => {
              return it.id === item.id
            })
            array.push(arr)
            this.ingredientSelect = array
          }
        }
      },
      async saveDishSort () {
        const obj = []
        const afterSortList = this.realDishList.map((it, i) => {
          it.sort = i + 1
          return it
        })
        for (const item of afterSortList) {
          obj.push({ id: item.id, sort: item.sort })
        }
        await settingDishIngredient({
          dishId: this.itemOfDish.id,
          idSorts: obj,
        })
        this.editItemSort = false
        showSuccessMessage()
      },
      async saveAttrSort () {
        const obj = []
        const afterSortList = this.realAttrList.map((it, i) => {
          it.sort = i + 1
          return it
        })
        for (const item of afterSortList) {
          obj.push({ id: item.id, sort: item.sort })
        }
        await settingToppingIngredient({
          attrId: this.attr.id,
          idSorts: obj,
        })
        this.editItemSort = false
        showSuccessMessage()
      },
      async dishSureSelect () {
        const obj = []
        for (const item of this.ingredientSelect) {
          const res = this.IngredientList[item]
          obj.push({ id: res.id, sort: 1 })
        }
        await settingDishIngredient({
          dishId: this.itemOfDish.id,
          idSorts: obj,
        })
        this.ingredientSelectDialog = false
        showSuccessMessage()
        await this.reload()
      },
      async attrSureSelect () {
        const obj = []
        for (const item of this.ingredientSelect) {
          const res = this.IngredientList[item]
          obj.push({ id: res.id, sort: 1 })
        }
        await settingToppingIngredient({
          attrId: this.attr.id,
          idSorts: obj,
        })
        this.ingredientSelectDialog = false
        showSuccessMessage()
        await this.reload()
      },
      async selectIngredient (item) {
        if (item.itemType === 1) {
          this.typeItem = 1
          this.attrItem = await getIngredientByAttr(item.id)
          this.editItem = true
          this.attrName = item.name
          this.attr = item
        } else {
          this.typeItem = 0
          this.dishItem = await getIngredientByDish(item.id)
          this.ingredientListName = this.dishItem.map(it => it.name)
          this.editItem = true
          this.nameOfDish = item.dishName
          this.itemOfDish = item
        }
      },
      async reload () {
        this.isLoading = true
        const dishRes = (await getIngredientDishListGroup())
        this.dishList = (await Dish.getList(true)).map(it => {
          if (!dishRes[it.id]) {
            it.ingredientListName = ''
          } else {
            it.ingredientListName = dishRes[it.id].map(x => x.name).join()
          }
          it.itemType = 0
          return it
        })
        const attrRes = (await getIngredientAttrListGroup())
        this.attrList = (await Attribute.getList(true)).map(it => {
          if (!attrRes[it.id]) {
            it.ingredientListName = ''
          } else {
            it.ingredientListName = attrRes[it.id].map(x => x.name).join()
          }
          it.itemType = 1
          return it
        })
        this.IngredientList = (await getIngredientList())
        this.SKUList = await getSKUList()
        this.isLoading = false
      },
    },
  }
</script>

<style scoped>

</style>
